import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Container, Form, Button, Alert, Spinner } from "react-bootstrap";
import { AppContext } from "../../AppContext";
import axios from "axios";

import { Settings } from "../../Settings";

function Login() {
  const location = useLocation();
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState(),
    [password, setPassword] = useState(),
    [loggedIn, setLoggedIn] = useState(),
    onInputUsername = ({ target: { value } }) => setUsername(value),
    onInputPassword = ({ target: { value } }) => setPassword(value),
    onFormSubmit = (e) => {
      e.preventDefault();

      setIsLoading(true);

      axios
        .post(`${Settings.API_URL}/token`, {
          username: username,
          password: password,
        })
        .then((res) => {
          if (res.status === 200 && res.data.success && res.data.token) {
            console.log(res);
            appContext.login(res.data.token, res.data.huid);
            setIsLoading(false);
            setUsername();
            setPassword();
            history.push("/reports");
          } else {
            setIsLoading(false);
            setShowError(true);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setShowError(true);
        });
    };

  useEffect(() => {
    var token = localStorage.getItem("token");

    if (!token) {
      token = appContext.token;
    }

    if (token) {
      const { from } = location.state || { from: { pathname: "/" } };
      history.replace(from);
    }

    window.addEventListener("storage", () => {
      if (localStorage.getItem("token")) {
        const { from } = location.state || { from: { pathname: "/" } };
        history.replace(from);
      }
    });
    return () => {
      // when the component unmounts remove the event listener
      window.removeEventListener("storage", () => {});
    };
  }, []);

  return (
    <Container>
      {showError ? (
        <Alert
          variant="danger"
          onClose={() => setShowError(false)}
          dismissible
          transition={false}
        >
          Login failed!
        </Alert>
      ) : (
        ""
      )}
      <Form>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            onChange={onInputUsername}
            type="email"
            placeholder="Enter email"
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            onChange={onInputPassword}
            type="password"
            placeholder="Password"
          />
        </Form.Group>

        <Button
          variant="primary"
          disabled={isLoading}
          onClick={!isLoading ? onFormSubmit : null}
        >
          {isLoading ? (
            <Spinner animation="border" variant="warning" />
          ) : (
            "Login"
          )}
        </Button>
      </Form>
    </Container>
  );
}

export default Login;
