import React from "react";

function Home() {
  return (
    <div id="content_wrap">
      <div id="ball_content_container" className="ixp-container">
        <div
          id="ball_ilVkgW0zyEqu7y8aCaOTw"
          style={{ paddingBottom: "20px" }}
          className="ixp-container"
        >
          <div id="ball_i9MKNKTwAkO4xuBrcN6l0w" className="ixp-container">
            
            <div
              id="ball_ieLcTULYSvk6L2kgkWqJr4A"
              style={{
                backgroundColor: "#e5f0f5",
                paddingTop: "4em",
                paddingBottom: "4em",
              }}
              className="content ixp-container"
            >
              <div
                id="ball_iCuNX7K5L0CtECTi2yxVw"
                className="ixp-grid-container ixp-container"
              >
                <div
                  id="ball_iDYrTt23gDUSerIm0GNA8g"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_iGpjOfkcViE2juS8FBGarSg"
                    className="ixp-grid-col-md-12 ixp-container"
                  >
                    <div
                      id="ball_iBFki6WUrLE6eOcy9jb18w"
                      style={{ borderLeft: "10px none #000" }}
                      className="ixp-text"
                    >
                      <h5>&nbsp;</h5>
                      <h5>
                        <span style={{ color: "#263037" }}>
                          <span style={{ fontSize: "16px" }}>
                            Designed and optimised for your workflow, our API
                            solution&nbsp;allows you to quickly integrate
                            timely, accurate airline financial and operational
                            data to fit right into your workflow.
                          </span>
                        </span>
                      </h5>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="ball_iuNSeRGyBEmkCpYshpQNdA"
                className="ixp-grid-container ixp-container"
              >
                <div
                  id="ball_icoUSbah76kye7H2eRXD5gg"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_iYDELEHemGEmtxTavL6TcjQ"
                    className="ixp-grid-col-md-1 ixp-grid-col-lg-1 ixp-container"
                  />
                  <div
                    id="ball_iVd3CGeolb03c87PcItmg"
                    className="ixp-grid-col-md-1 ixp-grid-col-lg-2 ixp-container"
                  >
                    <img
                      src="Algorithm.webp?v=06282021135224"
                      id="ball_ieGLzgQVjrUi52hyBFKKT1Q"
                      width={800}
                      className="ixp-image"
                      height={800}
                      border={0}
                    />
                  </div>
                  <div
                    id="ball_iUBA2HzdsE2aA0uwJIWEA"
                    className="ixp-grid-col-md-1 ixp-grid-col-lg-1 ixp-container"
                  />
                  <div
                    id="ball_imzfMh9ypRE2xQbAwjfWHA"
                    className="ixp-grid-col-md-1 ixp-grid-col-lg-1 ixp-container"
                  />
                  <div
                    id="ball_iUgbJY4KpEapYlZRTstIQw"
                    className="ixp-grid-col-md-1 ixp-grid-col-lg-2 ixp-container"
                  >
                    <img
                      src="Portfolio.webp?v=06282021144026"
                      id="ball_iD2OqxyxJ8EqFQB6iGEUQ"
                      width={800}
                      className="ixp-image"
                      height={800}
                      border={0}
                    />
                  </div>
                  <div
                    id="ball_iJMDu351LeEqKUpbGckGVVA"
                    className="ixp-grid-col-md-3 ixp-grid-col-lg-1 ixp-container"
                  />
                  <div
                    id="ball_iR5ggwa3X70KFNQftkk4Q"
                    className="ixp-grid-col-md-1 ixp-grid-col-lg-1 ixp-container"
                  />
                  <div
                    id="ball_ie7E5ec7tnEKWFAjYuODgKg"
                    className="ixp-grid-col-md-1 ixp-grid-col-lg-2 ixp-container"
                  >
                    <img
                      src="API.webp?v=06282021144805"
                      id="ball_iEPC4hUN58kiPFDlG9yU3Q"
                      width={800}
                      className="ixp-image"
                      height={800}
                      border={0}
                    />
                  </div>
                  <div
                    id="ball_iR89SsqlPmUSqu9iHskR8NQ"
                    className="ixp-grid-col-md-1 ixp-grid-col-lg-1 ixp-container"
                  />
                </div>
              </div>
              <div
                id="ball_i5P4ssY32WUqzBtRdO6HxQ"
                className="ixp-grid-container ixp-container"
              >
                <div
                  id="ball_i2VRvDCwjGUquRlaNxQfi7A"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_igAz7SqWrqE68W3gAu9RwWg"
                    className="ixp-grid-col-md-4 ixp-container"
                  >
                    <div id="ball_icOgQst9yP0Wi2yRh1LJ8Q" className="ixp-text">
                      <h5 style={{ textAlign: "center" }}>
                        <strong>
                          <span style={{ color: "#263037" }}>
                            <span style={{ fontSize: "16px" }}>
                              Automation{" "}
                            </span>
                          </span>
                        </strong>
                      </h5>
                      <p>&nbsp;</p>
                      <h5>
                        <span style={{ color: "#263037" }}>
                          <span style={{ fontSize: "16px" }}>
                            Automatically import{" "}
                            <strong>financial and operational data</strong>{" "}
                            directly into your internal preferred{" "}
                            <strong>credit</strong> or{" "}
                            <strong>CRM system</strong> in a{" "}
                            <strong>customisable</strong> format.{" "}
                          </span>
                        </span>
                      </h5>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                  <div
                    id="ball_iUlsD0i1Iak2wP6GPTuFxQA"
                    className="ixp-grid-col-md-4 ixp-container"
                  >
                    <div id="ball_iCo8I6HTOAUmPJqslw189A" className="ixp-text">
                      <h5 style={{ textAlign: "center" }}>
                        <strong>
                          <span style={{ color: "#263037" }}>
                            <span style={{ fontSize: "16px" }}>Reporting </span>
                          </span>
                        </strong>
                      </h5>
                      <p>&nbsp;</p>
                      <h5>
                        <span style={{ color: "#263037" }}>
                          <span style={{ fontSize: "16px" }}>
                            Prepare multiple versions of reports for different
                            uses (<strong>e.g. credit scoring</strong>,{" "}
                            <strong>CRM</strong> and{" "}
                            <strong>senior management briefings</strong>). You
                            can decide what ratios and parameters to display.{" "}
                          </span>
                        </span>
                      </h5>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                  <div
                    id="ball_i6CXsNaF6a0yQM07j4mwbQ"
                    className="ixp-grid-col-md-4 ixp-container"
                  >
                    <div id="ball_ipGitzUvQsUCGBHikSdcDvw" className="ixp-text">
                      <div style={{ textAlign: "center" }}>
                        <font color="#263037">
                          <span style={{ fontSize: "16px" }}>
                            <b>Speed &amp; agility</b>
                          </span>
                        </font>
                      </div>
                      <div style={{ textAlign: "center" }}>&nbsp;</div>
                      <h5>
                        <span style={{ color: "rgb(38, 48, 55)" }}>
                          <span style={{ fontSize: "16px" }}>
                            Greater agility for accessing and updating data.
                            Increased speed in{" "}
                            <strong>
                              meeting the demands your stakeholders place on you
                            </strong>
                            . You can capture all of the updated data from The
                            Airline Analyst by daily or more frequent calls.
                          </span>
                        </span>
                      </h5>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
                <div
                  id="ball_iAp3g1CVjUyq5WPNPFYQ"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_iHGjnGWp2DkKxn5eoVxjmw"
                    className="ixp-grid-col-md-1 ixp-container"
                  />
                  <div
                    id="ball_i8mfJmoWgUmH07DgYtlQ"
                    className="ixp-grid-col-md-2 ixp-container"
                  >
                    <img
                      src="Prog-Screen.webp?v=06282021145227"
                      id="ball_i2b0FOsteQky2pYagY8bNBQ"
                      width={800}
                      className="ixp-image"
                      height={800}
                      border={0}
                    />
                  </div>
                  <div
                    id="ball_ilTdYR67DcUi1rp8y3aXeiA"
                    className="ixp-grid-col-md-1 ixp-container"
                  />
                  <div
                    id="ball_itlgx5OTguUSYpME6GOxKIQ"
                    className="ixp-grid-col-md-1 ixp-container"
                  />
                  <div
                    id="ball_iwP1qDVttkaBOiKD0gDxHg"
                    className="ixp-grid-col-md-2 ixp-container"
                  >
                    <img
                      src="Wire-Frame.webp?v=06282021145541"
                      id="ball_iHcNDoGuEMEKUyB9ZT4KC3A"
                      width={800}
                      className="ixp-image"
                      height={800}
                      border={0}
                    />
                  </div>
                  <div
                    id="ball_ipwaRORk0dkqORqFa3C6RMg"
                    className="ixp-grid-col-md-1 ixp-container"
                  />
                  <div
                    id="ball_iZh8fGJ3RWE2zGRTwLyMLzg"
                    className="ixp-grid-col-md-1 ixp-container"
                  />
                  <div
                    id="ball_iCoR7hbh1MUO13q6zGebQew"
                    className="ixp-grid-col-md-2 ixp-container"
                  >
                    <img
                      src="Software.webp?v=06282021145713"
                      id="ball_iaMswekRgkKkphG8KpXQyg"
                      width={800}
                      className="ixp-image"
                      height={800}
                      border={0}
                    />
                  </div>
                  <div
                    id="ball_i7dV1Vx59FEuE30kcwFkLAg"
                    className="ixp-grid-col-md-1 ixp-container"
                  />
                </div>
                <div
                  id="ball_iAS7zCKzevkioftAomeGBMA"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_i0RcKF4yiZ0SXWg7TGwrWLg"
                    className="ixp-grid-col-md-4 ixp-container"
                  >
                    <div id="ball_iXKngwuaVj0GK1G2tUzHOMg" className="ixp-text">
                      <div style={{ textAlign: "center" }}>
                        <font color="#263037">
                          <span style={{ fontSize: "16px" }}>
                            <b>Enhanced productivity</b>
                          </span>
                        </font>
                      </div>
                      <div style={{ textAlign: "center" }}>&nbsp;</div>
                      <h5>
                        <span style={{ color: "rgb(38, 48, 55)" }}>
                          <span style={{ fontSize: "16px" }}>
                            Allow your team to focus on{" "}
                            <strong>providing better data analysis</strong>,
                            instead of spending the hundreds&nbsp;of hours
                            required to capture airline financial data in-house.
                          </span>
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div
                    id="ball_i8k6rgKFd7kokQPgi5ZKDA"
                    className="ixp-grid-col-md-4 ixp-container"
                  >
                    <div id="ball_iIn27Vi7X9keJU5lb3TkLPA" className="ixp-text">
                      <div style={{ textAlign: "center" }}>
                        <font color="#263037">
                          <span style={{ fontSize: "16px" }}>
                            <b>Streamlined operations</b>
                          </span>
                        </font>
                      </div>
                      <div style={{ textAlign: "center" }}>&nbsp;</div>
                      <h5>
                        <span style={{ color: "rgb(38, 48, 55)" }}>
                          <span style={{ fontSize: "16px" }}>
                            Streamline and simplify your processes and{" "}
                            <strong>achieve cost efficiencies</strong>. What
                            took days now takes minutes.
                          </span>
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div
                    id="ball_iJtMuFV2sEuJBogwvaaHQ"
                    className="ixp-grid-col-md-4 ixp-container"
                  >
                    <div id="ball_i7VIzqboeckKbHhZAiDpKbA" className="ixp-text">
                      <div style={{ textAlign: "center" }}>
                        <font color="#263037">
                          <span style={{ fontSize: "16px" }}>
                            <b>Simple integration</b>
                          </span>
                        </font>
                      </div>
                      <div style={{ textAlign: "center" }}>&nbsp;</div>
                      <h5>
                        <span style={{ color: "rgb(38, 48, 55)" }}>
                          <span style={{ fontSize: "16px" }}>
                            <strong>Easy</strong> to integrate,
                            <strong> simple</strong> to build on.&nbsp;
                          </span>
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  id="ball_ixDbV5PTjB0qzOlZUN9GkA"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_i8jj6B0B3ES5fZUIVgRuTw"
                    className="ixp-grid-col-md-12 ixp-container"
                  >
                    <div
                      id="ball_idr66faxiNkCz6g9GUXFug"
                      className="ixp-grid-row ixp-container"
                    >
                      <div
                        id="ball_imZTfxWnIkGx3jK3TvJbGg"
                        className="ixp-grid-col-xs-1 ixp-container"
                      />
                      <div
                        id="ball_ixJ2V7aYDLESh5hEsO9G9w"
                        className="ixp-grid-col-xs-10 ixp-container"
                      >
                        <img
                          src="TAA-API.webp?v=06282021093129"
                          id="ball_iqO1PZkdgUObmafVV3I3XA"
                          width={959}
                          className="ixp-image"
                          height={354}
                          style={{
                            borderTop: "10px solid #e5f0f5",
                            borderBottom: "10px solid #e5f0f5",
                            WebkitBorderRadius: "20px 20px 20px 20px",
                            MozBorderRadius: "20px 20px 20px 20px",
                            borderRadius: "20px 20px 20px 20px",
                          }}
                          border={0}
                        />
                        <img
                          src="Swagger.webp?v=06282021094812"
                          id="ball_iF6Xp6Rw6tkqelaxZGgTnA"
                          width={950}
                          className="ixp-image"
                          height={907}
                          style={{
                            borderTop: "1px solid #e5f0f5",
                            borderBottom: "10px solid #e5f0f5",
                            WebkitBorderRadius: "20px 20px 20px 20px",
                            MozBorderRadius: "20px 20px 20px 20px",
                            borderRadius: "20px 20px 20px 20px",
                          }}
                          border={0}
                        />
                      </div>
                      <div
                        id="ball_iwcS5bMIUZkeTLai9B4T8SA"
                        className="ixp-grid-col-xs-1 ixp-container"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="ball_iJ32HaZP3EUJDrnKJJsD2w"
                className="ixp-grid-container ixp-container"
              >
                <div
                  id="ball_i3YuZNUTq0kmWtFyMTGDLag"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_iqXGLORURU6INBJcV9MaIg"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                  <div
                    id="ball_iXtYJgCYNHkG1ZA07IO0hQ"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                  <div
                    id="ball_iNj3BKO1zUWgZkgrvW4Bpg"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                </div>
              </div>
              <div
                id="ball_izZszrOQuTECBeVXlSR6ewA"
                className="ixp-grid-container ixp-container"
              >
                <div
                  id="ball_iD3ybjcyuBkePdRGhSWF9g"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_ilELfdwIzH0qdYlYRvtfrhA"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                  <div
                    id="ball_iOGfdwwtKEKiIT0W4iZLNg"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                  <div
                    id="ball_ilcAgzHpiE0uvccBPwczMeA"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                </div>
              </div>
              <div
                id="ball_ixz7M4IVhbUptueKtsw9WA"
                className="ixp-grid-container ixp-container"
              >
                <div
                  id="ball_iIXoAnwCzkkiYu7FhPOOcFA"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_i3JhnK419ZkGWcH637LY9Vw"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                  <div
                    id="ball_iW2eMjcK0yEGEiD9obx4Qrw"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                  <div
                    id="ball_ioIlGM26ZX0mXYmPZhMpJw"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                </div>
                <div
                  id="ball_ie5xuMvLqWkSf0gmxIfOPQA"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_irw501yZf0EuBVYYHZR9hJg"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                  <div
                    id="ball_ig5MPG8N5uk2hdE1rpEvWzw"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                  <div
                    id="ball_inuAXjxvJkW4YQJdWHcnRw"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                </div>
                <div
                  id="ball_iyOi7NSmgEEawtkTv2mPSA"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_iYJR8bdVk0SZIllH8eIlcA"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                  <div
                    id="ball_iyPM1fTWQbk2YmhbD7cuA"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                  <div
                    id="ball_ixiVAnIeFsUm0hPkGSlFYKQ"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                </div>
              </div>
              <div
                id="ball_i1X0njxYWzECoqlwZznorQ"
                className="ixp-grid-container ixp-container"
              >
                <div
                  id="ball_ihziYPZZN3EKcnfq3hwmEAw"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_id6U6PJDSTU24QoV3KXM6Ng"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                  <div
                    id="ball_iz9pCcxO0Kztfi2p0afw"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                  <div
                    id="ball_ivLczv7BAiUie087nnz01Bw"
                    className="ixp-grid-col-md-4 ixp-container"
                  />
                </div>
              </div>
              <div
                id="ball_imoEOZa3XoEgDVC6zjwgmw"
                className="ixp-grid-container ixp-container"
              >
                <div
                  id="ball_ia6rPx4HnXkiDznmiVoms3w"
                  className="ixp-grid-row ixp-container"
                >
                  <div
                    id="ball_i2ZNkewRek0SV3GSsnQSO4Q"
                    style={{ paddingBottom: "1em" }}
                    className="ixp-grid-col-sm-6 ixp-grid-col-md-7 ixp-container"
                  >
                    <div
                      id="ball_idDl0zIWvFEipP1RDME1WQw"
                      className="ixp-grid-row ixp-smPadding5 ixp-padded-row-s ixp-animated-scroll ixp-scroll-aware ixp-scroll-in-once ixp-scroll-fadeDown ixp-animated-delay-20 ixp-container"
                    >
                      <div
                        id="ball_ipxTkED1hkKckYLfTQYEdQ"
                        className="ixp-grid-col-xs-12 ixp-container"
                      />
                    </div>
                  </div>
                  <div
                    id="ball_iM7dG7DF6Akaz0WYJ9NJySA"
                    className="ixp-grid-col-sm-6 ixp-grid-col-md-5 ixp-container"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
