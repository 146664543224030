import React, { useEffect, useState } from "react";

export const AppContext = React.createContext({
  token: null,
  login: () => {},
  logout: () => {},
});

const AppContextProvider = (props) => {
  const [token, setToken] = useState(null);
  const [huid, setHuid] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [newNotifications, setNewNotifications] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [isLogout, setIsLogout] = useState(false);

  const addNotification = (ndata) => {
    setNotifications((arr) => {
      if (
        arr.filter((n) => {
          return n.opid == ndata.opid;
        }).length
      ) {
        var newArr = arr.map((element) => {
          return element.opid == ndata.opid ? ndata : element;
        });
        return newArr;
      }
      return [...arr, ndata].reverse();
    });
  };

  // useEffect(() => {
  //   setNewNotifications((notif) => notif + 1);
  // }, [notifications]);

  const showSessionModalHandler = (show) => {
    setShowSessionModal(show);
  };

  const loginHandler = (token, huid) => {
    setToken(token);
    localStorage.setItem("token", token);
    setHuid(huid);
    localStorage.setItem("huid", huid);
  };

  const huidHandler = (huid) => {
    setHuid(huid);
    localStorage.setItem("huid", huid);
  };

  const logoutHandler = () => {
    setIsLogout(true);
    setToken(null);
    setNotifications([]);
    localStorage.removeItem("token");
    localStorage.removeItem("huid");
  };

  const showNotificationsHandler = (open) => {
    setShowNotifications(open);
  };

  const setNewNotificationsHandler = (numebrOfNewNotifications) => {
    setNewNotifications((newNotifs) => {
      return newNotifs + numebrOfNewNotifications;
    });
  };

  const clearNewNotificationsHandler = () => {
    setNewNotifications(0);
  };

  return (
    <AppContext.Provider
      value={{
        login: loginHandler,
        logout: logoutHandler,
        showSessionModalHandler: showSessionModalHandler,
        showNotificationsHandler: showNotificationsHandler,
        setNewNotificationsHandler: setNewNotificationsHandler,
        huidHandler: huidHandler,
        addNotification: addNotification,
        clearNewNotificationsHandler: clearNewNotificationsHandler,
        setIsLogout: setIsLogout,
        setNotifications: setNotifications,
        token: token,
        huid: huid,
        showSessionModal: showSessionModal,
        showNotifications: showNotifications,
        newNotifications: newNotifications,
        notifications: notifications,
        isLogout: isLogout,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
