import "./App.css";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  Button,
  Container,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { createBrowserHistory as history } from "history";
import ScrollToTop from "react-scroll-to-top";
import axios from "axios";
import { Modal, Drawer, Badge, Panel, PanelGroup } from "rsuite";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import moment from "moment";

import Reports from "./components/reports/Reports";
import Login from "./components/login/Login";
import { AppContext } from "./AppContext";
import PrivateRoute from "./PrivateRoute";
import { Settings } from "./Settings";
import Home from "./components/home/Home";

function App() {
  const appContext = useContext(AppContext);
  const [connection, setConnection] = useState(null);

  const [connAfterLogin, setConnAfterLogin] = useState(false);

  const hist = history();
  var token = null;

  useEffect(() => {
    token = localStorage.getItem("token");

    if (!token) {
      token = appContext.token;
    } else {
      appContext.login(token);
    }

    if (token) {
      const connect = new HubConnectionBuilder()
        .withUrl(`${Settings.API_URL}/Hubs/NotificationsHub`, {
          accessTokenFactory: () => {
            return token;
          },
        })
        .withAutomaticReconnect()
        .build();

      setConnection(connect);
    }
  }, []);

  useEffect(() => {
    console.log("useEffect appContext.token", connection, appContext.token);
    if (appContext.token && !connection) {
      const connect = new HubConnectionBuilder()
        .withUrl(`${Settings.API_URL}/Hubs/NotificationsHub`, {
          accessTokenFactory: () => {
            return appContext.token;
          },
        })
        .withAutomaticReconnect()
        .build();

      setConnection(connect);
      setConnAfterLogin(true);
    }
  }, [appContext.token]);

  useEffect(() => {
    if (connection && appContext.isLogout) {
      connection.stop();
      setConnection(null);
    }
  }, [appContext.isLogout]);

  useEffect(() => {
    console.log("useEffect SignalR connection", connection, appContext.token);
    if (connection && token) {
      console.log("SignalR connection");
      connection
        .start()
        .then(() => {
          connection.on("ReceiveMessage", (message) => {
            // console.log("ReceiveMessage message", message);
            appContext.addNotification(message.notification);
            //appContext.showNotificationsHandler(true);
            if (
              message.notification.status === 3 ||
              message.notification.status === 4
            )
              appContext.setNewNotificationsHandler(1);
          });
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);

  useEffect(() => {
    console.log("useEffect connAfterLogin", connection, appContext.token);
    if (connection && appContext.token) {
      console.log("SignalR connection");
      connection
        .start()
        .then(() => {
          connection.on("ReceiveMessage", (message) => {
            // console.log("ReceiveMessage message", message);
            appContext.addNotification(message.notification);
            //appContext.showNotificationsHandler(true);
            if (
              message.notification.status === 3 ||
              message.notification.status === 4
            )
              appContext.setNewNotificationsHandler(1);
          });
        })
        .catch((error) => console.log(error));
    }
  }, [connAfterLogin, appContext.token]);

  const handleLogout = () => {
    appContext.logout();
    hist.push("/");
  };

  const handleLogin = () => {
    appContext.logout();
    appContext.showSessionModalHandler(false);
    hist.push("/login");
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { status } = error.response;
      if (token && (status == 401 || status == 403)) {
        appContext.showSessionModalHandler(true);
      } else {
        return Promise.reject(error);
      }
    }
  );

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return "Pending";
        break;
      case 2:
        return "Processing";
        break;
      case 3:
        return "Ready for download";
        break;
      case 4:
        return "Error";
        break;
      case 5:
        return "Downloaded";
        break;
      case 6:
        return "Dismissed";
        break;
    }
  };

  const handelDismissed = (ndata) => {
    var token = localStorage.getItem("token");

    if (!token) {
      token = appContext.token;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(
        `${Settings.API_URL}/api/Downloads/DeleteNotifications/${ndata.opid}`,
        {
          headers,
        }
      )
      .then((res) => {
        appContext.setNotifications((arr) => {
          return arr.filter((element) => {
            return element.opid !== ndata.opid;
          });
        });
      })
      .catch((error) =>
        console.log(`Error dismissing notifications: ${error}`)
      );
  };

  const handelDownload = (notification) => {
    var token = localStorage.getItem("token");

    if (!token) {
      token = appContext.token;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios({
      url: `${Settings.API_URL}/api/Downloads/${notification.opid}`,
      method: "GET",
      responseType: "blob", // important
      headers: headers,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${notification.opid}.xls`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Router>
      <ScrollToTop smooth />

      <Container fluid={true}>
        <Modal
          show={appContext.showSessionModal}
          onHide={handleLogin}
          backdrop={"static"}
          keyboard={false}
          className={"authModal"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Authorization error</Modal.Title>
          </Modal.Header>
          <Modal.Body>You authorization has expired! Please login.</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleLogin}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <div
          id="ball_ilhGYadcaTk201XhLXGDaQ"
          style={{
            backgroundColor: "#fff",
            backgroundImage: 'url("headerbg.webp")',
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            marginTop: "15px",
          }}
          className="header-a ixp-container"
        >
          <div id="ball_ibQhy8Ut9EUGIQxlGultSDA" className="ixp-container">
            <div id="ball_i1XqYZb9JL0KaeGVR7oH0MQ" className="ixp-container">
              <div
                id="ball_idK84Nr9haE2BAN7ZVPCLw"
                className="ixp-grid-col-md-4 ixp-container"
              />
              <div
                id="ball_iZYTebrhKx0iDLlVfMLy1dg"
                className="ixp-grid-col-md-4 ixp-container"
              />
              <div
                id="ball_iigPEbXZUtkGcfSGyZufbHQ"
                className="ixp-grid-col-md-4 ixp-container"
              >
                <img
                  src="Airfinance-Journal-The-Airline-Analysts-Landscape-W.png?v=06282021095938"
                  id="ball_inZKI0rn0kW0TXCu4nUIWw"
                  width={2000}
                  className="ixp-image"
                  height={610}
                  border={0}
                />
              </div>
            </div>
          </div>
          <div id="ball_ikirHYlfFW0euqe2w5l5dGg">
            <div
              id="ball_ievqRaKV0QEe3PAdl9OfGwg"
              className="ixp-grid-row ixp-row-sm-12 ixp-container"
            >
              <div
                id="ball_iVjym357Uk02Xez6LdLmW0g"
                className="ixp-grid-col-sm-12 ixp-grid-col-md-2 ixp-grid-col-lg-3 ixp-container"
              >
                <div
                  id="ball_iKilOvKWKUiC8IfhVvTAw"
                  className="ixp-container ixp-container-link"
                  style={{
                    width: "250px",
                    maxWidth: "none",
                    height: "150px",
                    float: "left",
                    boxSizing: "border-box",
                    top: "22px",
                    left: "29px",
                    position: "absolute",
                    zIndex: 10,
                  }}
                />
              </div>
              <div
                id="ball_iSYnjwf6e0qCEEVTkFktGw"
                style={{ marginTop: "10px" }}
                className="align-right ixp-grid-col-sm-12 ixp-grid-col-md-9 ixp-grid-col-lg-9 ixp-container"
              >
                <div
                  id="ball_iE7K884NrNkGykFz6MLAAIg"
                  style={{ marginTop: "-5em" }}
                  className="ixp-grid-hidden-sm ixp-grid-hidden-md ixp-grid-hidden-lg ixp-container"
                />
                <div
                  id="ball_i9dPBbJiNE2h3UX3Tv8prQ"
                  style={{ backgroundColor: "rgba(0,0,0,0)" }}
                  className="ixp-animated ixp-fadeInDown ixp-container"
                >
                  <div
                    id="ball_i9xXqA6SAeEiY6FKwvW3Yww"
                    className="font-c ixp-text"
                  >
                    <h5>&nbsp;</h5>
                    <p style={{ textAlign: "right" }}>
                      <span style={{ color: "#FFFFFF" }}>
                        <span style={{ fontSize: "32px" }}>
                          <span
                            style={{
                              boxSizing: "border-box",
                              fontWeight: "bolder",
                            }}
                          >
                            API Solution for The Airline Analyst
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  id="ball_iWUZINgX3hEm4jrZu3Rth3g"
                  className="color-a ixp-animated ixp-fadeIn ixp-animated-delay-50 ixp-text"
                >
                  <p style={{ textAlign: "right", paddingBottom: "15px" }}>
                    <span style={{ color: "#FFFFFF" }}>
                      <span style={{ fontSize: "20px" }}>
                        <span
                          style={{
                            boxSizing: "border-box",
                            fontWeight: "bolder",
                          }}
                        >
                          The most powerful airline financial data engine in the
                          world{" "}
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Drawer
          open={appContext.showNotifications}
          width={300}
          onOpen={() => appContext.clearNewNotificationsHandler()}
          onClose={() => appContext.showNotificationsHandler(false)}
          placement={"right"}
        >
          <Drawer.Body>
            <PanelGroup>
              {appContext.notifications.map((element) => {
                return (
                  <Panel
                    header="Download Excel Request"
                    key={element.opid}
                    bordered
                  >
                    <Row>
                      <Col xs={3}>Date</Col>
                      <Col>
                        {moment(element.dt).format("MM.DD.YYYY H:mm:ss")}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3}>Status</Col>
                      <Col>{getStatus(element.status)}</Col>
                    </Row>
                    <Row>
                      {element.status === 3 ? (
                        <Col>
                          <Button
                            size={"sm"}
                            variant="secondary"
                            onClick={() => handelDownload(element)}
                          >
                            Download
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                      {element.status > 2 ? (
                        <Col>
                          <Button
                            size={"sm"}
                            variant="secondary"
                            onClick={() => handelDismissed(element)}
                          >
                            Dismiss
                          </Button>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>

                    <Panel header="Details" collapsible bordered>
                      <Row>
                        <Col xs={3}>Operation ID</Col>
                        <Col>{element.opid}</Col>
                      </Row>
                      <Row>
                        <Col xs={3}>Reference</Col>
                        <Col>{element.ref}</Col>
                      </Row>
                      {element.error ? (
                        <Row>
                          <Col xs={3}>Error</Col>
                          <Col>{element.error}</Col>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Panel>
                  </Panel>
                );
              })}
            </PanelGroup>
          </Drawer.Body>
        </Drawer>

        <Navbar bg="white" variant="light" expand="lg" sticky="top">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="reports">
                Reports
              </Nav.Link>
              {!appContext.token ? (
                <Nav.Link as={Link} to="login">
                  Login
                </Nav.Link>
              ) : (
                <Nav.Link as={Link} to={"#"} onClick={handleLogout}>
                  Logout
                </Nav.Link>
              )}
              <Nav.Link target={"_blank"} href={`${Settings.API_URL}/swagger`}>
                API Documentation
              </Nav.Link>
              <Nav.Link
                target={"_blank"}
                href={`${Settings.API_URL}/help.html`}
              >
                API Help Page
              </Nav.Link>
              <Nav.Link
                onClick={() => appContext.showNotificationsHandler(true)}
              >
                Notifications ({appContext.notifications.length}){" "}
                {appContext.newNotifications > 0 ? (
                  <Badge content={appContext.newNotifications}></Badge>
                ) : (
                  ""
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>

      <Container fluid={true}>
        <Switch>
          <PrivateRoute path="/reports" component={Reports} />
          <Route path="/login" component={Login} />
          <Route path="/" component={Home} />
        </Switch>
      </Container>

      <Container fluid={true} style={{ paddingTop: "50px" }}>
        <Card>
          <Card.Body>
            <a
              style={{ paddingRight: "30px" }}
              target={"_blank"}
              href={"https://www.euromoneyplc.com/modern-slavery-act"}
            >
              Modern Slavery Act
            </a>
            <a
              style={{ paddingRight: "30px" }}
              target={"_blank"}
              href={"https://www.airfinancejournal.com/privacy-policy"}
            >
              Privacy policy
            </a>
            <a
              style={{ paddingRight: "30px" }}
              target={"_blank"}
              href={"https://www.airfinancejournal.com/terms-and-conditions"}
            >
              Terms and conditions
            </a>
            <a
              style={{ paddingRight: "30px" }}
              target={"_blank"}
              href={"https://www.airfinancejournal.com/cookies"}
            >
              Cookie policy
            </a>
          </Card.Body>
        </Card>
      </Container>
    </Router>
  );
}

export default App;
