import React, { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router";

import { AppContext } from "./AppContext";

const PrivateRoute = (props) => {
  const location = useLocation();
  const appContext = useContext(AppContext);

  return appContext.token ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
