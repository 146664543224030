import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../AppContext";
import { Form, Button, Alert, Row, Col, Spinner } from "react-bootstrap";
import {
  CheckPicker,
  SelectPicker,
  Radio,
  RadioGroup,
  Pagination,
  Checkbox,
} from "rsuite";
import ReactJson from "react-json-view";
import axios from "axios";
import "rsuite/dist/rsuite.min.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { Settings } from "../../Settings";

function Reports() {
  const appContext = useContext(AppContext);
  const [airlinesData, setAirlinesData] = useState([]),
    [airlines, setAirlines] = useState(["AFR"]),
    [reportTypes, setReportTypes] = useState(["BS"]),
    [reportPeriod, setReportPeriod] = useState("A"),
    [startYear, setStartYear] = useState(2020),
    [endYear, setEndYear] = useState(2020),
    [aircraftRentCapitalFactor, setAircraftRentCapitalFactor] = useState("8"),
    [grossNetDebtForLeveragePurposes, setGrossNetDebtForLeveragePurposes] =
      useState("GrossDeductNoCash"),
    [fxType, setFxType] = useState("Reported"),
    [units, setUnits] = useState("Km"),
    [reportsData, setReportsData] = useState(null),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(null),
    [responses, setResponses] = useState([]),
    [showError, setShowError] = useState(false),
    [errorMessage, setErrorMessage] = useState(null),
    [isPagination, setIsPagination] = useState(false),
    [reportsSelection, setReportsSelection] = useState("Reports"),
    [isLoading, setIsLoading] = useState(false),
    [download, setDownload] = useState(false),
    onDownloadCB = (value, checked, e) => {
      setDownload(checked);
      console.log(checked);
    },
    onInputAirlines = (value) => {
      setAirlines(value);
    },
    onInputReportTypes = (value) => setReportTypes(value),
    onInputReportPeriod = (value) => setReportPeriod(value),
    onInputStartYear = (value) => setStartYear(value),
    onInputEndYear = (value) => setEndYear(value),
    onInputRentFactor = (value) => setAircraftRentCapitalFactor(value),
    onInputGrossNetDebt = (value) => setGrossNetDebtForLeveragePurposes(value),
    onInputFxType = (value) => setFxType(value),
    onInputUnits = (value) => setUnits(value),
    onFormSubmit = (e) => {
      if (e) e.preventDefault();

      handleNewQuery();

      setShowError(false);
      setErrorMessage(null);
      setIsLoading(true);

      var token = localStorage.getItem("token");

      if (!token) {
        token = appContext.token;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      var request = {
        afjIcaoCodesList: airlines,
        reportTypes: reportTypes,
        reportPeriod: reportPeriod,
        startYear: startYear,
        endYear: endYear,
        userSettings: {
          operatingLeaseMultiple: aircraftRentCapitalFactor,
          grossNetDebtForLeveragePurposes: grossNetDebtForLeveragePurposes,
          fxType: fxType,
          units: units,
        },
        page: currentPage,
      };

      if (download) {
        var downloadRequest = {
          request: request,
          type: 1,
        };
        axios
          .post(`${Settings.API_URL}/api/Downloads`, downloadRequest, {
            headers,
          })
          .then((res) => {
            setIsLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.errorMesage) {
                setErrorMessage(error.response.data.errorMesage);
              }
            }
            setShowError(true);
            setIsLoading(false);
          });
        return;
      }

      axios
        .post(`${Settings.API_URL}/api/Reports`, request, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            setReportsData(res.data);
            setTotalPages(res.data.totalPages);
            setIsLoading(false);
          } else {
            if (res.data.errorMesage) {
              setErrorMessage(res.data.errorMesage);
            }
            setShowError(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.errorMesage) {
              setErrorMessage(error.response.data.errorMesage);
            }
          }
          setShowError(true);
          setIsLoading(false);
        });
    },
    onFormSubmitLTM = (e) => {
      if (e) e.preventDefault();

      handleNewQuery();

      setShowError(false);
      setErrorMessage(null);
      setIsLoading(true);

      var token = localStorage.getItem("token");

      if (!token) {
        token = appContext.token;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      var request = {
        afjIcaoCodesList: airlines,
        startYear: startYear,
        endYear: endYear,
        userSettings: {
          operatingLeaseMultiple: aircraftRentCapitalFactor,
          grossNetDebtForLeveragePurposes: grossNetDebtForLeveragePurposes,
          fxType: fxType,
          units: units,
        },
        page: currentPage,
      };

      if (download) {
        var downloadRequest = {
          request: request,
          type: 2,
        };
        axios
          .post(`${Settings.API_URL}/api/Downloads`, downloadRequest, {
            headers,
          })
          .then((res) => {
            setIsLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.errorMesage) {
                setErrorMessage(error.response.data.errorMesage);
              }
            }
            setShowError(true);
            setIsLoading(false);
          });
        return;
      }

      axios
        .post(`${Settings.API_URL}/api/Reports/LTMReport`, request, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            setReportsData(res.data);
            setTotalPages(res.data.totalPages);
            setIsLoading(false);
          } else {
            if (res.data.errorMesage) {
              setErrorMessage(res.data.errorMesage);
            }
            setShowError(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.errorMesage) {
              setErrorMessage(error.response.data.errorMesage);
            }
          }
          setShowError(true);
          setIsLoading(false);
        });
    },
    onFormSubmitKPI = (e) => {
      if (e) e.preventDefault();

      handleNewQuery();

      setShowError(false);
      setErrorMessage(null);
      setIsLoading(true);

      var token = localStorage.getItem("token");

      if (!token) {
        token = appContext.token;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      var request = {
        afjIcaoCodesList: airlines,
        year: startYear,
        userSettings: {
          operatingLeaseMultiple: aircraftRentCapitalFactor,
          grossNetDebtForLeveragePurposes: grossNetDebtForLeveragePurposes,
          fxType: fxType,
          units: units,
        },
        page: currentPage,
      };

      if (download) {
        var downloadRequest = {
          request: request,
          type: 3,
        };
        axios
          .post(`${Settings.API_URL}/api/Downloads`, downloadRequest, {
            headers,
          })
          .then((res) => {
            setIsLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.errorMesage) {
                setErrorMessage(error.response.data.errorMesage);
              }
            }
            setShowError(true);
            setIsLoading(false);
          });
        return;
      }

      axios
        .post(`${Settings.API_URL}/api/Reports/KPIReport`, request, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            setReportsData(res.data);
            setTotalPages(res.data.totalPages);
            setIsLoading(false);
          } else {
            if (res.data.errorMesage) {
              setErrorMessage(res.data.errorMesage);
            }
            setShowError(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.errorMesage) {
              setErrorMessage(error.response.data.errorMesage);
            }
          }
          setShowError(true);
          setIsLoading(false);
        });
    };

  const reportTypesData = [
    { label: "Balance Sheet", value: "BS" },
    { label: "Income Statement", value: "IS" },
    { label: "Cash Flow Statement", value: "CF" },
  ];
  const reportPeriodsData = [
    { label: "Annual", value: "A" },
    { label: "SemiAnnual", value: "S" },
    { label: "Quarterly", value: "Q" },
  ];
  const grossNetData = [
    {
      label: "Net Deduct 100 Percent Of Unrestricted Cash",
      value: "NetDeduct100PercentOfUnrestrictedCash",
    },
    {
      label: "Deduct Unrestricted Cash > 5% Of Ann Revs",
      value: "DeductUnrestrictedCashGt5PercentOfAnnRevs",
    },
    {
      label: "Deduct Unrestricted Cash > 10% Of Ann Revs",
      value: "DeductUnrestrictedCashGt10PercentOfAnnRevs",
    },
    {
      label: "Deduct Unrestricted Cash > 15% Of Ann Revs",
      value: "DeductUnrestrictedCashGt15PercentOfAnnRevs",
    },
    {
      label: "Deduct Unrestricted Cash > 25% Of Ann Revs",
      value: "DeductUnrestrictedCashGt25PercentOfAnnRevs",
    },
    {
      label: "Deduct Unrestricted Cash > 75% Of Ann Revs",
      value: "DeductUnrestrictedCashGt75PercentOfAnnRevs",
    },
    {
      label: "Deduct Unrestricted Cash > 100 % Of Ann Revs",
      value: "DeductUnrestrictedCashGt100PercentOfAnnRevs",
    },
    { label: "Gross Deduct No Cash", value: "GrossDeductNoCash" },
  ];
  const fxTypeData = [
    { label: "Reported", value: "Reported" },
    { label: "Historic", value: "Historic" },
    { label: "Spot", value: "Spot" },
  ];
  const unitsData = [
    { label: "Km", value: "Km" },
    { label: "Mi", value: "Mi" },
  ];
  const year = new Date().getFullYear();
  const years = Array.from(new Array(25), (val, index) => year - index);
  const yearsData = years.map((item) => ({
    label: item,
    value: item,
  }));

  const rentFactor = Array.from(new Array(11), (val, index) => 0 + index);
  const rentFactorData = rentFactor.map((item) => ({
    label: item,
    value: item.toString(),
  }));
  const styles = { width: 224, display: "block", marginBottom: 10 };

  const handlePagination = (eventKey) => {
    setIsPagination(true);
    setReportsData(null);
    setCurrentPage(eventKey);
  };

  const handleNewQuery = () => {
    setIsPagination(false);
    setReportsData(null);
  };

  const handleReportsChange = (e) => {
    handleNewQuery();
    setReportsSelection(e);
    setCurrentPage(1);
  };

  const handleAData = (data) => {
    setAirlinesData(data);
  };

  useEffect(() => {
    if (isPagination) onFormSubmit(null);
  }, [currentPage, isPagination]);

  useEffect(() => {
    setShowError(false);

    var adata = JSON.parse(localStorage.getItem("airlinesData"));

    handleAData(adata);

    var token = localStorage.getItem("token");

    if (!token) {
      token = appContext.token;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${Settings.API_URL}/api/Airlines`, { headers })
      .then((res) => {
        if (res.status === 200) {
          var data = res.data.data.map((item) => ({
            label: item.companyName,
            value: item.airlineIcaoCode,
          }));
          localStorage.setItem("airlinesData", JSON.stringify(data));
          handleAData(adata);
          //setAirlinesData(data);
        } else {
          setShowError(true);
        }
      })
      .catch((error) => {
        setShowError(true);
      });
  }, []);

  return (
    <>
      {showError ? (
        <Alert
          variant="danger"
          transition={false}
          onClose={() => setShowError(false)}
          dismissible
        >
          Ooops, something went wrong!
          <p> {errorMessage} </p>
        </Alert>
      ) : (
        ""
      )}
      <Row>
        <Col lg="4">
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="reportsgroup">
                  <Form.Label size="lg">Reports</Form.Label>
                  <RadioGroup
                    name="radioList"
                    defaultValue="Reports"
                    onChange={handleReportsChange}
                  >
                    <Radio value="Reports">
                      Balance Sheet, Income Statement, Cash Flow Statement
                    </Radio>
                    <Radio value="LTM">LTM</Radio>
                    <Radio value="KPI">KPI</Radio>
                  </RadioGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formBasicAirlines">
                  <Form.Label size="sm">Airlines</Form.Label>
                  <CheckPicker
                    onChange={onInputAirlines}
                    size="sm"
                    placeholder="Select"
                    data={airlinesData}
                    style={styles}
                    defaultValue={["AFR"]}
                  />
                </Form.Group>
                {reportsSelection == "Reports" ? (
                  <>
                    <Form.Group controlId="formBasicReportTypes">
                      <Form.Label size="sm">Report Types</Form.Label>
                      <CheckPicker
                        onChange={onInputReportTypes}
                        size="sm"
                        placeholder="Select"
                        data={reportTypesData}
                        style={styles}
                        defaultValue={["BS"]}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicReportPeriod">
                      <Form.Label size="sm">Report Period</Form.Label>
                      <SelectPicker
                        onChange={onInputReportPeriod}
                        size="sm"
                        placeholder="Select"
                        data={reportPeriodsData}
                        style={styles}
                        defaultValue="A"
                      />
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}
                <Form.Group controlId="formBasicReportYears">
                  <Form.Label size="sm">
                    {reportsSelection != "KPI" ? "Years Range" : "Year"}
                  </Form.Label>
                  <SelectPicker
                    onChange={onInputStartYear}
                    size="sm"
                    placeholder={reportsSelection != "KPI" ? "From" : "Year"}
                    data={yearsData}
                    style={styles}
                    defaultValue={2020}
                  />
                  {reportsSelection != "KPI" ? (
                    <SelectPicker
                      onChange={onInputEndYear}
                      size="sm"
                      placeholder="To"
                      data={yearsData}
                      style={styles}
                      defaultValue={2020}
                    />
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formAircraftRentCapitalisationFactor">
                  <Form.Label size="sm">
                    Aircraft Rent Capitalisation Factor
                  </Form.Label>
                  <SelectPicker
                    onChange={onInputRentFactor}
                    size="sm"
                    placeholder="Select"
                    data={rentFactorData}
                    style={styles}
                    defaultValue="8"
                  />
                </Form.Group>

                <Form.Group controlId="formGrossNetDebtForLeveragePurposes">
                  <Form.Label size="sm">
                    Gross Net Debt For Leverage Purposes
                  </Form.Label>
                  <SelectPicker
                    onChange={onInputGrossNetDebt}
                    size="sm"
                    placeholder="Select"
                    data={grossNetData}
                    style={styles}
                    defaultValue="GrossDeductNoCash"
                  />
                </Form.Group>

                <Form.Group controlId="formFxType">
                  <Form.Label size="sm">Fx Type</Form.Label>
                  <SelectPicker
                    onChange={onInputFxType}
                    size="sm"
                    placeholder="Select"
                    data={fxTypeData}
                    style={styles}
                    defaultValue="Reported"
                  />
                </Form.Group>

                <Form.Group controlId="formUnits">
                  <Form.Label size="sm">Units</Form.Label>
                  <SelectPicker
                    onChange={onInputUnits}
                    size="sm"
                    placeholder="Select"
                    data={unitsData}
                    style={styles}
                    defaultValue="Km"
                  />
                </Form.Group>

                <Checkbox style={{ paddingRight: 20 }} onChange={onDownloadCB}>
                  {" "}
                  Download Excel
                </Checkbox>

                {reportsSelection == "Reports" ? (
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    onClick={!isLoading ? onFormSubmit : null}
                  >
                    {isLoading ? (
                      <Spinner animation="border" variant="warning" />
                    ) : (
                      "Query"
                    )}
                  </Button>
                ) : (
                  ""
                )}
                {reportsSelection == "LTM" ? (
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    onClick={!isLoading ? onFormSubmitLTM : null}
                  >
                    {isLoading ? (
                      <Spinner animation="border" variant="warning" />
                    ) : (
                      "Query"
                    )}{" "}
                  </Button>
                ) : (
                  ""
                )}
                {reportsSelection == "KPI" ? (
                  <Button
                    variant="primary"
                    disabled={isLoading}
                    onClick={!isLoading ? onFormSubmitKPI : null}
                  >
                    {isLoading ? (
                      <Spinner animation="border" variant="warning" />
                    ) : (
                      "Query"
                    )}
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Form>
        </Col>
        <Col>
          {reportsData ? (
            <>
              {totalPages ? (
                <Pagination
                  pages={totalPages}
                  maxButtons={10}
                  activePage={currentPage}
                  onSelect={handlePagination}
                  first={true}
                  prev={true}
                  last={true}
                  next={true}
                />
              ) : (
                ""
              )}
              <CopyToClipboard
                text={JSON.stringify(reportsData)}
                onCopy={() => console.log("Copied")}
              >
                <Button>
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </CopyToClipboard>

              <ReactJson
                src={reportsData}
                onAdd={false}
                onDelete={false}
                onEdit={false}
                displayDataTypes={false}
                enableClipboard={true}
                collapsed={false}
                // theme={"isotope"}
                indentWidth={2}
                displayObjectSize={false}
                collapsed={10}
              />
            </>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  );
}

export default Reports;
